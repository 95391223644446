.landingpage{
.footer-main {
  width: 100%;
  display: inline-block;
  background-color: black;
  text-align: center;
  padding: 20px 0;
}

.footer-main .footer {
  width: auto;
  display: inline-block;
}

.footer-main .footer h2 {
  color: white;
  margin-top: 20px;
}

@media (min-width: 320px) {
  .footer-main .footer h2 {
    font-size: 21px;
    margin: 0;
  }
}

@media (min-width: 480px) {
  .footer-main .footer h2 {
    font-size: 24px;
    margin: 10px;
  }
}

@media (min-width: 768px) {
  .footer-main .footer h2 {
    font-size: 26px;
    margin: 12px;
  }
}

@media (min-width: 992px) {
  .footer-main .footer h2 {
    font-size: 30px;
    margin: 14px;
  }
}

.footer-main .footer p {
  color: white;
  margin-top: 40px;
  margin-bottom: 10px;
}

@media (min-width: 320px) {
  .footer-main .footer p {
    margin-top: 10px;
    font-size: 12px;
  }
}

@media (min-width: 480px) {
  .footer-main .footer p {
    font-size: 14px;
    margin-top: 14px;
  }
}

@media (min-width: 768px) {
  .footer-main .footer p {
    font-size: 18px;
    margin-top: 18px;
  }
}

@media (min-width: 992px) {
  .footer-main .footer p {
    font-size: 22px;
    margin-top: 20px;
  }
}

.footer-main .footer input {
  display: block;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  border: none;
}

.footer-main .footer button,
.footer-main .footer input[type=submit] {
  border: none;
  margin: 30px auto;
  background-color: #9b111e;
  color: white;
  width: 100%;
  max-width: 140px;
  padding: 10px 0px;
  border-radius: 10px;
  letter-spacing: 1px;
  font-weight: 700;
}

.footer-main .footer .copyright {
  width: auto;
  color: #fff;
}

.footer-main .footer .copyright a {
  color: white;
  text-decoration: underline;
  position: relative;
  padding-left: 20px;
}


@media (min-width: 320px) {
  .footer-main .footer .copyright a::before {
    display: none;
  }
}

@media (min-width: 480px) {

  .footer-main .footer .copyright a,
  .footer-main .footer .copyright {
    font-size: 14px;
  }

  .footer-main .footer .copyright a::before {
    content: '';
    width: 2px;
    height: 15px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
}

@media (min-width: 768px) {

  .footer-main .footer .copyright a,
  .footer-main .footer .copyright {
    font-size: 16px;
  }
}

.header-main {
  width: 100%;
  display: inline-block;
  background-color: white;
}

@media (min-width: 320px) {
  .header-main {
    padding: 15px 0;
  }
}

.header-main .header {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.header-main .header .logo {
  width: auto;
}

.header-main .header .logo img {
  max-width: 300px;
}

@media (min-width: 320px) {
  .header-main .header .logo img {
    max-width: 140px;
  }
}

@media (min-width: 992px) {
  .header-main .header .logo img {
    max-width: 300px;
  }
}

.header-main .header .support {
  width: auto;
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
}

@media (min-width: 320px) {
  .header-main .header .support {
    display: contents;
  }
}

@media (min-width: 768px) {
  .header-main .header .support {
    display: inherit;
  }
}

.header-main .header .support nav {
  display: inline-block;
  text-align: right;
}

.header-main .header .support nav ul {
  padding-top: 10px;
}

.header-main .header .support nav ul li {
  display: inline-block;
}

.header-main .header .support nav ul li a {
  text-decoration: none;
  color: black;
  font-weight: 700;
  padding: 0 10px 0 0;
}

@media (min-width: 320px) {
  .header-main .header .support nav ul li a {
    padding: 0px 3px 0 3px;
    font-size: 12px;
  }
}

@media (min-width: 992px) {
  .header-main .header .support nav ul li a {
    padding: 0 7px 0 7px;
    font-size: 18px;
  }
}

.header-main .header .support nav ul li a:hover {
  color: #9b111e;
}

.header-main .header .support button {
  color: white;
  background-color: #9b111e;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  padding: 8px 50px;
  font-weight: 700;
  font-size: 18px;
}

.header-main .header .support button:hover {
  color: white;
  background-color: black;
}

body {
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3 {
  font-weight: 700;
}

a,
:hover,
:focus {
  transition: 1s all ease;
}

.btn {
  color: white;
  background-color: #9b111e;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  padding: 8px 50px;
  font-weight: 700;
  font-size: 18px;
}

.banner-main {
  width: 100%;
  display: inline-block;
  background-color: #9b111e;
  padding: 80px 0;
  text-align: left;
}

@media (min-width: 320px) {
  .banner-main {
    text-align: center;
  }
}

.banner-main .banner {
  position: relative;
}

.banner-main .banner h2 {
  color: white;
  font-size: 75px;
  line-height: 70px;
}

@media (min-width: 320px) {
  .banner-main .banner h2 {
    font-size: 24px;
    // position: absolute;
    // top: 136px;
    // width: 100%;
    // text-align: center;
    line-height: 1.6;
  }
}

@media (min-width: 480px) {
  .banner-main .banner h2 {
    font-size: 30px;
    line-height: 42px;
  }
}

@media (min-width: 768px) {
  .banner-main .banner h2 {
    font-size: 38px;
    width: inherit;
    text-align: left;
    position: inherit;
    top: 0;
  }
}

@media (min-width: 992px) {
  .banner-main .banner h2 {
    font-size: 46px;
    position: inherit;
    top: 0;
  }
}

@media (min-width: 1200px) {
  .banner-main .banner h2 {
    font-size: 48px;
    line-height: 70px;
  }
}

.banner-main .banner p {
  color: white;
  font-size: 25px;
}

@media (min-width: 320px) {
  .banner-main .banner p {
    margin-top: 75px;
    font-size: 16px;
  }
}

@media (min-width: 480px) {
  .banner-main .banner p {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .banner-main .banner p {
    font-size: 20px;
    text-align: left;
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .banner-main .banner p {
    font-size: 22px;
    margin-top: 0px;
  }
}

@media (min-width: 1200px) {
  .banner-main .banner p {
    font-size: 26px;
  }
}

.banner-main .banner img {
  width: 250px;
  // position: absolute;
  top: -20px;
  right: 0;
}

@media (min-width: 320px) {
  .banner-main .banner img {
    position: inherit;
    top: -34px;
    width: 160px;
  }
}

@media (min-width: 480px) {
  .banner-main .banner img {
    // position: absolute;
    top: 0;
  }
}

@media (min-width: 768px) {
  .banner-main .banner img {
    width: 150px;
  }
}

@media (min-width: 992px) {
  .banner-main .banner img {
    // position: absolute;
    top: 0;
    width: 200px;
  }
}

@media (min-width: 1200px) {
  .banner-main .banner img {
    width: 250px;
    // position: absolute;
    right: 0;
  }
}

.payxtreme-main {
  width: 100%;
  display: inline-block;
  background-color: white;
  padding: 60px 0;
  position: relative;
  overflow: hidden;
  // float: left;
}

.payxtreme-main:after {
  content: "";
  width: 200px;
  height: 200px;
  position: absolute;
  border-radius: 50%;
  top: -70px;
  right: -70px;
  background-color: #d7a0a5;
}

@media (min-width: 320px) {
  .payxtreme-main:after {
    width: 150px;
    height: 150px;
  }
}

@media (min-width: 768px) {
  .payxtreme-main:after {
    width: 180px;
    height: 180px;
  }
}

@media (min-width: 1200px) {
  .payxtreme-main:after {
    width: 210px;
    height: 210px;
  }
}

.payxtreme-main:before {
  content: "";
  width: 200px;
  height: 200px;
  position: absolute;
  border-radius: 50%;
  bottom: -70px;
  left: -70px;
  background-color: #d7a0a5;
}

@media (min-width: 320px) {
  .payxtreme-main:before {
    width: 150px;
    height: 150px;
  }
}

@media (min-width: 768px) {
  .payxtreme-main:before {
    width: 180px;
    height: 180px;
  }
}

@media (min-width: 1200px) {
  .payxtreme-main:before {
    width: 210px;
    height: 210px;
  }
}

.payxtreme-main h2 {
  display: table;
  font-size: 20px;
  padding: 8px 14px;
  text-align: center;
  font-size: 50px;
  margin: 0 auto;
  padding: 8px 0;
  text-transform: uppercase;
  border-bottom: 1px solid #777777;
  color: #777777;
}

@media (min-width: 320px) {
  .payxtreme-main h2 {
    display: table;
    font-size: 20px;
    padding: 8px 14px;
  }
}

@media (min-width: 480px) {
  .payxtreme-main h2 {
    font-size: 22px;
    padding: 7px 12px;
  }
}

@media (min-width: 768px) {
  .payxtreme-main h2 {
    font-size: 28px;
    padding: 8px 14px;
  }
}

@media (min-width: 992px) {
  .payxtreme-main h2 {
    font-size: 40px;
  }
}

.payxtreme-main .payxtreme {
  width: 100%;
  text-align: center;
  margin-top: 60px;
}

@media (min-width: 320px) {
  .payxtreme-main .payxtreme {
    margin-top: 30px;
  }
}

.payxtreme-main .payxtreme h3 {
  font-weight: 700;
  font-size: 42px;
}

@media (min-width: 320px) {
  .payxtreme-main .payxtreme h3 {
    font-size: 19px;
  }
}

@media (min-width: 480px) {
  .payxtreme-main .payxtreme h3 {
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  .payxtreme-main .payxtreme h3 {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .payxtreme-main .payxtreme h3 {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  .payxtreme-main .payxtreme h3 {
    font-size: 32px;
  }
}

.payxtreme-main .payxtreme p {
  font-size: 22px;
  color: black;
  padding-top: 20px;
}

@media (min-width: 320px) {
  .payxtreme-main .payxtreme p {
    padding-top: 0;
    font-size: 14px;
  }
}

@media (min-width: 480px) {
  .payxtreme-main .payxtreme p {
    padding-top: 0;
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .payxtreme-main .payxtreme p {
    padding-top: 10px;
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .payxtreme-main .payxtreme p {
    font-size: 20px;
    padding-top: 20px;
  }
}

.payxtreme-main .payxtreme img {
  width: 80%;
}

@media (min-width: 320px) {
  .payxtreme-main .payxtreme img {
    width: 50%;
  }
}

@media (min-width: 480px) {
  .payxtreme-main .payxtreme img {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .payxtreme-main .payxtreme img {
    width: 70%;
  }
}

@media (min-width: 992px) {
  .payxtreme-main .payxtreme img {
    width: 80%;
  }
}

.payment-main {
  width: 100%;
  display: inline-block;
  background-color: #e6e6e6;
  padding: 80px;
  text-align: center;
  position: relative;
  overflow: hidden;
  // float: left;
}

@media (min-width: 320px) {
  .payment-main {
    padding: 20px;
  }
}

@media (min-width: 480px) {
  .payment-main {
    padding: 30px;
  }
}

@media (min-width: 768px) {
  .payment-main {
    padding: 40px;
  }
}

@media (min-width: 992px) {
  .payment-main {
    padding: 50px;
  }
}

@media (min-width: 1200px) {
  .payment-main {
    padding: 60px;
  }
}

.payment-main:after {
  content: "";
  width: 200px;
  height: 200px;
  position: absolute;
  border-radius: 50%;
  top: -70px;
  right: -70px;
  background-color: #9f9f9f;
}

@media (min-width: 320px) {
  .payment-main:after {
    width: 150px;
    height: 150px;
  }
}

@media (min-width: 768px) {
  .payment-main:after {
    width: 180px;
    height: 180px;
  }
}

@media (min-width: 1200px) {
  .payment-main:after {
    width: 210px;
    height: 210px;
  }
}

.payment-main:before {
  content: "";
  width: 200px;
  height: 200px;
  position: absolute;
  border-radius: 50%;
  bottom: -70px;
  left: -70px;
  background-color: #9f9f9f;
}

@media (min-width: 320px) {
  .payment-main:before {
    width: 150px;
    height: 150px;
  }
}

@media (min-width: 768px) {
  .payment-main:before {
    width: 180px;
    height: 180px;
  }
}

@media (min-width: 1200px) {
  .payment-main:before {
    width: 210px;
    height: 210px;
  }
}

.payment-main .row {
  margin-top: 60px;
}

@media (min-width: 320px) {
  .payment-main .row {
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  .payment-main .row {
    margin-top: 40px;
  }
}

.payment-main h2 {
  display: table;
  text-align: center;
  font-size: 50px;
  margin: 0 auto;
  padding: 8px 0;
  text-transform: uppercase;
  border-bottom: 1px solid #777777;
  color: #777777;
  margin-bottom: 40px;
}

@media (min-width: 320px) {
  .payment-main h2 {
    margin: 0 auto;
    font-size: 20px;
    margin-top: 60px;
    padding: 5px 0;
  }
}

@media (min-width: 480px) {
  .payment-main h2 {
    font-size: 30px;
    margin-top: 70px;
  }
}

@media (min-width: 768px) {
  .payment-main h2 {
    font-size: 36px;
    margin-top: 80px;
  }
}

@media (min-width: 992px) {
  .payment-main h2 {
    font-size: 40px;
    margin-top: 80px;
  }
}

.payment-main .number {
  width: 100%;
}

.payment-main .number span img {
  width: 100%;
}

@media (min-width: 320px) {
  .payment-main .number span img {
    width: 30%;
  }
}

@media (min-width: 480px) {
  .payment-main .number span img {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .payment-main .number span img {
    width: 70%;
  }
}

@media (min-width: 992px) {
  .payment-main .number span img {
    width: 80%;
  }
}

@media (min-width: 1200px) {
  .payment-main .number span img {
    width: 90%;
  }
}

.payment-main .icon {
  width: 100%;
}

.payment-main .icon img {
  width: 80%;
  margin-top: 10px;
  filter: drop-shadow(-7px 4px 6px green);
}

@media (min-width: 320px) {
  .payment-main .icon img {
    width: 50%;
  }
}

.payment-main .description {
  width: 100%;
  max-width: 700px;
  margin: 10px auto;
}

.payment-main .description h3 {
  font-weight: 700;
  font-size: 42px;
}

@media (min-width: 320px) {
  .payment-main .description h3 {
    font-size: 19px;
  }
}

@media (min-width: 480px) {
  .payment-main .description h3 {
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  .payment-main .description h3 {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .payment-main .description h3 {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  .payment-main .description h3 {
    font-size: 32px;
  }
}

.payment-main .description p {
  font-size: 22px;
  color: black;
  padding-top: 20px;
}

@media (min-width: 320px) {
  .payment-main .description p {
    font-size: 14px;
    padding-top: 0;
  }
}

@media (min-width: 480px) {
  .payment-main .description p {
    font-size: 16px;
    padding-top: 10px;
  }
}

@media (min-width: 768px) {
  .payment-main .description p {
    font-size: 18px;
    padding-top: 0;
  }
}

@media (min-width: 992px) {
  .payment-main .description p {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .payment-main .description p {
    font-size: 20px;
    padding-top: 0;
  }
}


}
/*# sourceMappingURL=style.css.map */